import AgentRankCardContainer from './AgentRankCardContainer';
import AgentStatisticCard from './AgentStatisticCard';
import AgentActiveHours from './AgentActiveHours';
import { Box, Typography, Grid } from '@mui/material';
import { AgentMonitorContainer } from '../CallMonitorStyle';
import { RangeState } from './ConversationMonitor';

interface AgentMonitorProps {
  range: RangeState;
}

const AgentMonitor = ({ range }: AgentMonitorProps) => {
  return (
    <div id='agent-monitor'>
      <Box mt={4}>
        <Typography
          variant='h6'
          color='primary'
          sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}
        >
          CS Performance
        </Typography>
        <hr style={{ marginBottom: '1.5rem' }} />
        <AgentActiveHours range={range} />

        <AgentMonitorContainer>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <AgentStatisticCard range={range} />{' '}
            </Grid>

            <AgentRankCardContainer range={range} />
          </Grid>
        </AgentMonitorContainer>
      </Box>
    </div>
  );
};

export default AgentMonitor;
