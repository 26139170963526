// Component to store global state to provide necessary data for children

import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONFIG_AUTH } from '../../Utility/conf';
import {
  clearCredential,
  clearDate,
  clearConfirmLabelSave,
  clearConfirmLabelDelete,
  getCredential,
  saveCredential,
} from '../../Session/Session';

interface Data {
  id: number;
  username: string;
  token: string;
  permissions: string[];
  image: string;
}
export interface UserData {
  isAuth: boolean;
  data: Data;
}

interface UserFunction {
  changeAuthToFalse: () => void;
  changeAuthToTrue: (data: Data) => void;
}

export interface AuthenticationState {
  auth: UserData;
  func: UserFunction;
}

interface AuthenticationProps {
  children: React.ReactNode;
}

const defaultAuth: AuthenticationState = {
  auth: {
    isAuth: CONFIG_AUTH,
    data: {
      id: -1,
      username: '',
      token: '',
      permissions: [],
      image: '',
    },
  },
  func: {
    changeAuthToFalse: () => undefined,
    changeAuthToTrue: () => undefined,
  },
};

export const AuthContext = createContext(defaultAuth);

const Authentication = ({ children }: AuthenticationProps) => {
  const navigate = useNavigate();

  const tempAuth: AuthenticationState = {
    auth: {
      isAuth: CONFIG_AUTH,
      data: {
        id: -1,
        username: '',
        token: '',
        permissions: [],
        image: '',
      },
    },
    func: {
      changeAuthToFalse: () => undefined,
      changeAuthToTrue: () => undefined,
    },
  };
  tempAuth.auth = getCredential();
  const [auth, setAuth] = useState(tempAuth);

  const changeAuthToFalse = () => {
    clearCredential();
    clearConfirmLabelSave();
    clearConfirmLabelDelete();
    clearDate();
    setAuth({
      auth: {
        isAuth: CONFIG_AUTH,
        data: {
          id: -1,
          username: '',
          token: '',
          permissions: [],
          image: '',
        },
      },
      func: {
        changeAuthToFalse: () => undefined,
        changeAuthToTrue: () => undefined,
      },
    });
    navigate('/');
  };

  const changeAuthToTrue = (data: Data) => {
    const newAuth = defaultAuth;
    newAuth.auth.isAuth = true;
    newAuth.auth.data = data;
    saveCredential(newAuth.auth);
    setAuth(newAuth);
  };

  return (
    <AuthContext.Provider
      value={{
        auth: auth.auth,
        func: {
          changeAuthToFalse: changeAuthToFalse,
          changeAuthToTrue: changeAuthToTrue,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Authentication;
