import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { convertHMSColon } from '../../../Utility/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { RangeTypeTopicDetail } from '../TopicSentimentDetail';
import { DateRangeInterface } from '../../../react-app-env';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../Components/Style/Style';
import { SortingRule } from 'react-table';
import { useQuery } from 'react-query';
import {
  getTopicList,
  TopicSentimentDetailContentInterface,
  TopicSentimentDetailInterface,
} from '../../../Model/TopicSentiment';
import { Box } from '@mui/material';
import CustomReactTable, {
  TableStateType,
} from '../../../Components/CustomReactTable/CustomReactTable';
import { CustomerListSelectType } from '../../../Model/Customer';
import { TopicSentimentDetailTitle } from '../TopicSentimentDetailStyle';

type TopicListProps = {
  range: RangeTypeTopicDetail;
  setRange: React.Dispatch<React.SetStateAction<RangeTypeTopicDetail>>;
};

export type FilterState = {
  customer: CustomerListSelectType[];
  duration: {
    from: number;
    to: number;
  };
  rude_words_count: {
    from: number;
    to: number;
  };
  score: {
    from: number;
    to: number;
  };
};

const initialFilterState: FilterState = {
  customer: [],
  duration: {
    from: 0,
    to: 356400,
  },
  rude_words_count: {
    from: 0,
    to: 1000,
  },
  score: {
    from: 0,
    to: 1000,
  },
};

const TopicList = ({ range, setRange }: TopicListProps) => {
  const { topic } = useParams<Record<string, string | undefined>>();
  const filterStateRef = useRef<FilterState>(initialFilterState);
  const navigate = useNavigate();
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<TopicSentimentDetailContentInterface[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [isEnable, setIsEnable] = useState(true);
  const [filterState, setFilterState] =
    useState<FilterState>(initialFilterState);

  const [tableState, setTableState] = useState<TableStateType>({
    sortBy: [],
    pageIndex: 0,
    pageSize: 10,
  });

  const [controlledHiddenColumns, setControlledHiddenColumns] = useState<
    string[]
  >([]);

  const columns = [
    {
      Header: 'No',
      Cell: ({ row }: any) => {
        const { pageIndex, pageSize } = tableState;
        const baseLine = pageIndex * pageSize;
        return baseLine + row.index + 1;
      },
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Conversation Name',
      accessor: 'conversation_name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Customer',
      accessor: 'customer_name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Duration',
      accessor: 'duration',
      Cell: ({ row }: any) => {
        const duration = row.original.duration;
        if (duration === undefined) return '';
        return convertHMSColon(Math.floor(duration));
      },
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Positive',
      accessor: 'positive',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Neutral',
      accessor: 'neutral',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Negative',
      accessor: 'negative',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  useQuery<TopicSentimentDetailInterface | string | false>(
    'FetchAllAgentConversation',
    () => {
      if (topic === undefined) {
        navigate(-1);
        return 'Topic Undefined';
      }
      return getTopicList(
        tableState.pageIndex,
        tableState.pageSize,
        authentication.auth.data.token,
        range.startDate,
        range.endDate,
        topic
      );
    },
    {
      onSuccess: (result) => {
        if (result === false) {
          authentication.func.changeAuthToFalse();
        } else if (typeof result === 'string') {
          toast.warning(result, toastConfig);
        } else {
          setData(result.content);
          setPageCount(result.total_pages);
        }
      },
      onError: () => {
        toast.warning('Something went wrong', toastConfig);
      },
      enabled: isEnable,
      onSettled: () => {
        setIsEnable(false);
      },
    }
  );

  useEffect(() => {
    setIsEnable(true);
  }, [range]);

  const setStartEndDate = (range: DateRangeInterface) => {
    setRange({
      startDate: dayjs(range.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(range.endDate).format('YYYY-MM-DD'),
    });
  };

  useEffect(() => {
    setIsEnable(true);
  }, [tableState]);

  const applyFilter = () => {
    setTableState({ ...tableState, pageIndex: 0 });
    setFilterState({ ...filterStateRef.current });
    setIsEnable(true);
  };

  const cancelFilter = () => {
    filterStateRef.current = filterState;
    setFilterState((c) => ({ ...c }));
  };

  const clearFilter = () => {
    const emptyState: FilterState = {
      customer: [],
      duration: {
        from: 0,
        to: 356400,
      },
      rude_words_count: {
        from: 0,
        to: 1000,
      },
      score: {
        from: 0,
        to: 1000,
      },
    };
    setFilterState(() => ({ ...emptyState }));
    filterStateRef.current = emptyState;
  };

  const handleSort = useCallback(
    ({ sortBy }: { sortBy: SortingRule<Record<string, any>>[] }) => {
      setTableState((c) => ({ ...c, sortBy }));
    },
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setTableState((c) => ({ ...c, pageIndex, pageSize }));
    },
    []
  );

  return (
    <div id='agent-history'>
      <TopicSentimentDetailTitle>List Topics</TopicSentimentDetailTitle>
      <Box>
        <CustomReactTable
          columns={columns}
          data={data}
          onSort={handleSort}
          fetchData={fetchData}
          loading={isEnable}
          pageCount={pageCount}
          pageSize={tableState.pageSize}
          currentPage={tableState.pageIndex}
          setCurrentPage={(page) =>
            setTableState({ ...tableState, pageIndex: page })
          }
          controlledHiddenColumns={controlledHiddenColumns}
          setControlledHiddenColumns={setControlledHiddenColumns}
          applyFilter={applyFilter}
          cancelFilter={cancelFilter}
          clearFilter={clearFilter}
          itemDetailRoute='transcription'
          idName='id'
          fromWhere='topic'
          setStartEndDate={setStartEndDate}
          tableToolbarContainerSxProps={{
            py: 0,
          }}
          tableContainerSxProps={{
            p: 0,
          }}
          isHasFilter={false}
        />
      </Box>
    </div>
  );
};

export default TopicList;
