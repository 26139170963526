import SummaryChart from './ChartTopicSentimentSummary';
import DistributionsChart from './ChartTopicSentimentDistributions';
import TopicSentimentRanksCard from './TopicSentimentRanksCard';
import { StatisticCardTopicSentiment } from './StatisticCardContainer';
import { Box, Grid, Typography } from '@mui/material';
import { RangeState } from './ConversationMonitor';

interface TopicSentimentMonitorProps {
  range: RangeState;
}

const TopicSentimentMonitor = ({ range }: TopicSentimentMonitorProps) => {
  return (
    <Box mt={2}>
      <Typography
        variant='h6'
        color='primary'
        sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}
      >
        Conversation's Topic & Sentiment
      </Typography>
      <hr style={{ margin: '0.5rem 0 1.5rem' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <StatisticCardTopicSentiment range={range} />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Box
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            flexDirection='column'
          >
            <SummaryChart range={range} />
          </Box>
        </Grid>
      </Grid>
      <DistributionsChart range={range} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TopicSentimentRanksCard sentiment='positive' range={range} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TopicSentimentRanksCard sentiment='neutral' range={range} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TopicSentimentRanksCard sentiment='negative' range={range} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopicSentimentMonitor;
