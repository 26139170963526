import { useContext, useEffect, useState } from 'react';
import { getTopicSentimentStatistics } from '../../../Model/TopicSentiment';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { ChartContainer } from '../CallMonitorStyle';
import { PrimaryColor } from '../../../Components/Style/Style';
import { CustomAxisTick } from './Chart';
import { getMonthAndDateString } from '@/Utility/utils';
import { isValid, parseISO } from 'date-fns';
import { RangeState } from './ConversationMonitor';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface ChartProps {
  range: RangeState;
}

interface CustomTooltipProps {
  payload?: any;
  label?: string;
  active?: boolean;
}

const CustomTooltip = ({ payload, label, active }: CustomTooltipProps) => {
  const valueArray = payload?.map((item: any) => item.value);
  const total =
    valueArray.length === 0
      ? 0
      : valueArray.reduce((prev: number, next: number) => prev + next);
  const finalItems = [
    {
      fill: '#444',
      name: 'Total',
      value: total,
    },
    ...payload,
  ];
  if (active) {
    return (
      <div
        style={{
          borderRadius: '10px',
          background: 'white',
          border: '1px solid rgba(0, 55, 112, 0.24)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 10px 0 0',
            background: '#f2fcff',
            color: '#065dcc',
            padding: '0.25rem',
            fontFamily: 'Poppins',
          }}
        >
          <h4>{label}</h4>
        </div>
        <div style={{ padding: '0 1rem' }}>
          {finalItems.reverse().map((item: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '150px',
                margin: '8px 0',
                fontFamily: 'Poppins',
              }}
              key={`custom-tooltip-charttopicsum-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    background: item.fill,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                {item.name}
              </div>
              <h4 style={{ color: PrimaryColor, fontWeight: 700 }}>
                {item.value}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const ChartTopicSentimentSummary = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTopicSentimentStatistics = async () => {
      const result = await getTopicSentimentStatistics(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else {
        const tempResult = result.map((element: any) => {
          const item = element;
          const date = new Date(element.name);
          if (isValid(parseISO(element.name)))
            item.name = getMonthAndDateString(date);
          return item;
        });
        setData(tempResult);
      }
    };
    fetchTopicSentimentStatistics();
  }, [range, authentication]);

  return (
    <ChartContainer>
      <div style={{ padding: '0 1.5rem' }}>
        <div style={{ marginBottom: '1.5rem' }}>
          <h3
            style={{
              marginTop: 0,
              fontFamily: 'Montserrat',
              color: PrimaryColor,
            }}
          >
            Sentiment Trends
          </h3>
        </div>
        <div style={{ height: '320px', marginLeft: '-2rem' }}>
          <ResponsiveContainer width='99%' height='100%'>
            <BarChart
              data={data}
              margin={{
                bottom: 32,
              }}
            >
              <XAxis
                dataKey='name'
                style={{ fontFamily: 'Poppins' }}
                height={120}
                interval={0}
                tick={<CustomAxisTick />}
              />
              <YAxis allowDecimals={false} style={{ fontFamily: 'Poppins' }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                barSize={30}
                dataKey='negative'
                stackId='a'
                fill='#FB4848'
                name='Negative'
              />
              <Bar
                barSize={30}
                dataKey='neutral'
                stackId='a'
                fill='#006EFE'
                name='Neutral'
              />
              <Bar
                barSize={30}
                dataKey='positive'
                stackId='a'
                fill='#3FE4BC'
                name='Positive'
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </ChartContainer>
  );
};

export default ChartTopicSentimentSummary;
