import StatisticCard from './StatisticCard';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { getTopicSentimentSummary } from '@/Model/TopicSentiment';
import { RangeState } from './ConversationMonitor';
import { getTranscriptionSummary } from '../../../Model/Transcription';
import { convertHMS } from '../../../Utility/utils';
import { StatisticCardProps } from './StatisticCard';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../Components/Style/Style';
import { getConversationsDuration } from '../../../Model/CallMonitor';

const DummyDuration: StatisticCardProps = {
  type: 'duration',
  title: 'DURATION',
  number: -999,
  content: [
    {
      color: '#3569B7',
      label: 'Total Duration',
      content: '1h 26m 14s',
    },
    {
      color: '#42CAF4',
      label: 'Longest Duration',
      content: '26m 14s',
    },
    {
      color: '#6A39D2',
      label: 'Average Duration',
      content: '14s',
    },
  ],
};

const DummyTranscription: StatisticCardProps = {
  type: 'transcription',
  title: 'NUMBER OF CALLS',
  number: -999,
  content: [
    {
      color: '#3FE4BC',
      label: 'Succeeded',
      content: '20',
    },
    {
      color: '#006EFE',
      label: 'In Progress',
      content: '10',
    },
    {
      color: '#DCDCDC',
      label: 'On Queue',
      content: '12',
    },
    {
      color: '#FB4848',
      label: 'Failed',
      content: '2',
    },
  ],
};

const DummyTopicSentiment: StatisticCardProps = {
  type: 'transcription',
  title: 'NUMBER OF TOPICS',
  number: -999,
  content: [
    {
      color: '#3FE4BC',
      label: 'Positive',
      content: '20',
    },
    {
      color: '#006EFE',
      label: 'Neutral',
      content: '10',
    },
    {
      color: '#FB4848',
      label: 'Negative',
      content: '2',
    },
  ],
};

interface ChartProps {
  range: RangeState;
}

export const StatisticCardTranscription = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);
  const [dataTranscription, setDataTranscription] =
    useState(DummyTranscription);

  useEffect(() => {
    const fetchTranscriptionSummary = async () => {
      const result = await getTranscriptionSummary(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else {
        const tempContent = DummyTranscription.content;
        tempContent[0].content = result.success.toString();
        tempContent[1].content = result.started.toString();
        tempContent[2].content = result.not_started.toString();
        tempContent[3].content = result.failed.toString();
        setDataTranscription((c) => ({
          ...c,
          number: result.total,
          content: tempContent,
        }));
      }
    };

    fetchTranscriptionSummary();
  }, [range, authentication]);

  return <StatisticCard {...dataTranscription} />;
};

export const StatisticCardDuration = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);
  const [dataDuration, setDataDuration] = useState(DummyDuration);

  useEffect(() => {
    const fetchDurationStatistics = async () => {
      const result = await getConversationsDuration(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        const tempContent = DummyDuration.content;
        tempContent[0].content = convertHMS(Math.floor(result.total));
        tempContent[1].content = convertHMS(Math.floor(result.longest));
        tempContent[2].content = convertHMS(Math.floor(result.average));
        setDataDuration((c) => ({
          ...c,
          number: convertHMS(Math.floor(result.total)),
          content: tempContent,
        }));
      }
    };
    fetchDurationStatistics();
  }, [range, authentication]);

  return <StatisticCard {...dataDuration} />;
};

export const StatisticCardTopicSentiment = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);
  const [dataTopicSentiment, setDataTopicSentiment] =
    useState(DummyTopicSentiment);

  useEffect(() => {
    const fetchTopicSentimentSummary = async () => {
      const result = await getTopicSentimentSummary(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else {
        const tempContent = DummyTopicSentiment.content;
        tempContent[0].content = result.positive.toString();
        tempContent[1].content = result.neutral.toString();
        tempContent[2].content = result.negative.toString();
        const number = result.negative + result.neutral + result.positive;
        setDataTopicSentiment((c) => ({
          ...c,
          number,
          content: tempContent,
        }));
      }
    };

    fetchTopicSentimentSummary();
  }, [range, authentication]);

  return <StatisticCard {...dataTopicSentiment} />;
};
