import { useContext, useState, useEffect, MouseEvent, useMemo } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { Transition } from 'react-transition-group';
import {
  HoverSideBarItem,
  SidebarContent,
  SidebarContentName,
} from './SideBarStyle';
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowRight,
  Dashboard,
  QuestionAnswer,
  SupportAgent,
  Person,
  Assignment,
  EmojiEmotions,
  History,
} from '@mui/icons-material';
import { PrimaryColor } from '../Style/Style';

interface MenuInterface {
  id: number;
  icon: JSX.Element;
  url: string;
  alias: JSX.Element;
  show: boolean;
  child?: {
    content: JSX.Element;
    url: string;
    show: boolean;
  }[];
}

interface MenuComponentInterface extends MenuInterface {
  showAlias: boolean;
  isHasParent?: boolean;
  setIsToggle: (isToggle: boolean) => void;
  isOnPopover?: boolean;
}

const SideBarItem = ({
  icon,
  url,
  alias,
  showAlias,
  isHasParent,
  isOnPopover,
  setIsToggle,
}: MenuComponentInterface) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    if (!showAlias) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (!showAlias) setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SidebarContent
        onClick={() => {
          navigate(url);
          setIsToggle(false);
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        id={url.substring(1).split('/').join('-')}
      >
        {icon}
        <SidebarContentName
          style={{
            visibility: showAlias || isHasParent ? 'visible' : 'hidden',
            marginLeft: isOnPopover ? '' : '2.5rem',
            fontFamily: isHasParent ? 'Open Sans' : 'Poppins',
          }}
        >
          {alias}
        </SidebarContentName>
        <Box
          position='absolute'
          display={open && !showAlias ? 'block' : 'none'}
          top={0}
          left='70px'
          width='167.5px'
        >
          <Box
            bgcolor={PrimaryColor}
            color='white'
            borderRadius='0px'
            display='flex'
            px={2}
            alignItems='center'
            width='100%'
            height='56px'
            fontFamily='Open Sans'
            fontWeight={600}
          >
            {alias}
          </Box>
        </Box>
      </SidebarContent>
    </>
  );
};

const duration = 500;

const defaultStyle = {
  transition: `all ${duration}ms`,
  opacity: 0,
  maxHeight: '0px',
  maxWidth: '0px',
};

const transitionStyles: any = {
  entering: { opacity: 1, maxHeight: '16rem', maxWidth: '250px' },
  entered: { opacity: 1, maxHeight: '16rem', maxWidth: '250px' },
  exiting: { opacity: 0, maxHeight: '0px', maxWidth: '0px' },
  exited: { opacity: 0, maxHeight: '0px', maxWidth: '0px' },
};

const SideBarItemHidden = ({
  id,
  icon,
  alias,
  child,
  showAlias,
  setIsToggle,
}: MenuComponentInterface) => {
  const [isHidden, setIsHidden] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    if (!showAlias) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    if (!showAlias) setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!showAlias) setIsHidden(true);
  }, [showAlias]);

  return (
    <>
      <HoverSideBarItem>
        <SidebarContent
          onClick={() => {
            if (showAlias) {
              setIsHidden((c) => !c);
            } else if (!open) {
              setIsToggle(true);
              setIsHidden((c) => !c);
            }
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {icon}
          <SidebarContentName
            style={{
              visibility: showAlias ? 'visible' : 'hidden',
              marginLeft: '2.5rem',
            }}
          >
            <Box mr={1}>{alias}</Box>{' '}
            {isHidden ? <ArrowDropDown /> : <ArrowDropUp />}
          </SidebarContentName>
          <Box
            position='absolute'
            display={open && !showAlias ? 'block' : 'none'}
            top={0}
            left='70px'
            bgcolor={PrimaryColor}
            width='200px'
          >
            <Box
              color='white'
              borderRadius='0px'
              display='flex'
              px={2}
              alignItems='center'
              width='100%'
              height='56px'
              fontFamily='Open Sans'
              fontWeight={600}
            >
              {alias}
            </Box>
            {child !== undefined ? (
              <>
                {child.map((data, index) => {
                  if (data.show) {
                    return (
                      <SideBarItem
                        show={data.show}
                        key={index}
                        id={id}
                        icon={<></>}
                        url={data.url}
                        alias={data.content}
                        showAlias
                        isHasParent
                        setIsToggle={(isToggle) => setIsToggle(isToggle)}
                        isOnPopover
                      />
                    );
                  } else {
                    return '';
                  }
                })}
              </>
            ) : (
              ''
            )}
          </Box>
        </SidebarContent>
        {child !== undefined ? (
          // @ts-ignore
          <Transition in={!isHidden} timeout={500}>
            {(state) => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                {child.map((data, index) => {
                  if (data.show) {
                    return (
                      <SideBarItem
                        show={data.show}
                        key={index}
                        id={id}
                        icon={<></>}
                        url={data.url}
                        alias={data.content}
                        showAlias={data.show && showAlias}
                        setIsToggle={(isToggle) => setIsToggle(isToggle)}
                      />
                    );
                  } else {
                    return '';
                  }
                })}
              </div>
            )}
          </Transition>
        ) : (
          ''
        )}
      </HoverSideBarItem>
    </>
  );
};

interface SideBarProps {
  isToggleDrawer: boolean;
  setIsToggle: (isToggle: boolean) => void;
  isClickingMenuBotton: boolean;
  preventClickAway: () => void;
}

const SideBar = ({
  isToggleDrawer,
  setIsToggle,
  isClickingMenuBotton,
  preventClickAway,
}: SideBarProps) => {
  const authentication = useContext(AuthContext);

  const Menu: MenuInterface[] = useMemo(
    () => [
      {
        id: 1,
        icon: <Dashboard style={{ fontSize: '2rem' }} />,
        url: '/call-monitor',
        alias: <>Call Monitor</>,
        show: authentication.auth.data.permissions.includes(
          'statistics.conversations'
        ),
      },
      {
        id: 2,
        icon: <QuestionAnswer style={{ fontSize: '2rem' }} />,
        url: '/conversation',
        alias: <>Conversation</>,
        show: true,
      },
      {
        id: 3,
        icon: <SupportAgent style={{ fontSize: '2rem' }} />,
        url: '/agent',
        alias: <>CS Agent</>,
        show:
          authentication.auth.data.permissions.includes(
            'features.ca_scoring'
          ) &&
          authentication.auth.data.permissions.includes('statistics.agents'),
      },
      {
        id: 4,
        icon: <EmojiEmotions style={{ fontSize: '2rem' }} />,
        url: '/topic-sentiment',
        alias: <>Topic & Sentiment</>,
        show: authentication.auth.data.permissions.includes(
          'features.sentiments'
        ),
      },
      {
        id: 5,
        icon: <Person style={{ fontSize: '2rem' }} />,
        url: '',
        alias: <>User Management</>,
        show:
          authentication.auth.data.permissions.includes('users.read') ||
          authentication.auth.data.permissions.includes('roles.read') ||
          authentication.auth.data.permissions.includes('customers.read') ||
          authentication.auth.data.permissions.includes('agents.read'),
        child: [
          {
            content: (
              <>
                <ArrowRight />
                User Manager{' '}
              </>
            ),
            url: '/administration/users',
            show: authentication.auth.data.permissions.includes('users.read'),
          },
          {
            content: (
              <>
                <ArrowRight />
                Roles & Rights{' '}
              </>
            ),
            url: '/administration/role-rights',
            show: authentication.auth.data.permissions.includes('roles.read'),
          },
          {
            content: (
              <>
                <ArrowRight />
                Customer{' '}
              </>
            ),
            url: '/administration/customers',
            show: authentication.auth.data.permissions.includes(
              'customers.read'
            ),
          },
          {
            content: (
              <>
                <ArrowRight />
                Agent{' '}
              </>
            ),
            url: '/administration/agent',
            show: authentication.auth.data.permissions.includes('agents.read'),
          },
        ],
      },

      {
        id: 6,
        icon: <Assignment style={{ fontSize: '2rem' }} />,
        url: '',
        alias: <>Feature Administration</>,
        show:
          authentication.auth.data.permissions.includes('sop.read') ||
          (authentication.auth.data.permissions.includes(
            'features.sentiments'
          ) &&
            authentication.auth.data.permissions.includes('whitelist.read') &&
            authentication.auth.data.permissions.includes('blacklist.read') &&
            authentication.auth.data.permissions.includes('synonym.read')),
        child: [
          {
            content: (
              <>
                <ArrowRight />
                SOP Rules{' '}
              </>
            ),
            url: '/sop',
            show: authentication.auth.data.permissions.includes('sop.read'),
          },
          {
            content: (
              <>
                <ArrowRight />
                Topic Manager{' '}
              </>
            ),
            url: '/topic-administration',
            show:
              authentication.auth.data.permissions.includes(
                'features.sentiments'
              ) &&
              authentication.auth.data.permissions.includes('whitelist.read') &&
              authentication.auth.data.permissions.includes('blacklist.read') &&
              authentication.auth.data.permissions.includes('synonym.read'),
          },
        ],
      },
      {
        id: 7,
        icon: <History style={{ fontSize: '2rem' }} />,
        url: '/audit-trail-logs',
        alias: <>Audit Report</>,
        show: authentication.auth.data.permissions.includes(
          'audit.access_log.read'
        ),
      },
    ],
    [authentication]
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!isClickingMenuBotton) setIsToggle(false);
        else preventClickAway();
      }}
    >
      <Box
        position='fixed'
        width={isToggleDrawer ? '250px' : '70px'}
        height='100%'
        bgcolor={PrimaryColor}
        zIndex={9}
        top={0}
        sx={{ transition: 'width .4s ease' }}
      >
        <Box mt='58px'>
          {Menu.map((data, index) => {
            if (data.show) {
              if (data.child)
                return (
                  <SideBarItemHidden
                    key={index}
                    showAlias={isToggleDrawer}
                    setIsToggle={(isToggle) => setIsToggle(isToggle)}
                    {...data}
                  />
                );
              return (
                <SideBarItem
                  key={index}
                  showAlias={isToggleDrawer}
                  setIsToggle={(isToggle) => setIsToggle(isToggle)}
                  {...data}
                />
              );
            }
            return '';
          })}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SideBar;
