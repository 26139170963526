import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomDateRangePicker from '../CustomDateRangePicker/CustomDateRangePicker';
import create from 'zustand';
import FilterTableForm from './Components/FilterTableForm';
import { useNavigate } from 'react-router-dom';
import type { Dispatch } from 'react';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useStyles } from './CustomReactTableStyle';
import { DateRangeInterface } from '../../react-app-env';
import {
  Box,
  Badge,
  TextField,
  FormControl,
  SxProps,
  IconButton,
  InputAdornment,
  Pagination,
  Grid,
  Button,
  Dialog,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  Search,
  TuneOutlined,
} from '@mui/icons-material';
import {
  useTable,
  usePagination,
  useSortBy,
  Column,
  SortingRule,
  useFilters,
  FilterProps,
} from 'react-table';
import ShowN from './Components/ShowN';
import NoResultsFound from './Components/NoResultsFound';

interface HighlighParamType {
  type: 'score' | 'duration' | 'avg_score';
  more: boolean;
  number: number;
  aboveZeroCondition?: string;
}

export interface TableStateType {
  sortBy: SortingRule<Record<string, any>>[];
  pageSize: number;
  pageIndex: number;
}

interface StoreHighlightTableInterface {
  highlight: string;
  highlightParam: HighlighParamType;
  setHighlight: (highlight: string) => void;
  setHighlightParam: (highlightParam: HighlighParamType) => void;
}

export const useStoreHighlightTable = create<StoreHighlightTableInterface>(
  (set) => ({
    highlight: '',
    highlightParam: {
      type: 'duration',
      more: false,
      number: 0,
    },
    setHighlight: (highlight) =>
      set(() => ({
        highlight,
      })),
    setHighlightParam: (highlightParam) =>
      set(() => ({
        highlightParam,
      })),
  })
);

interface CustomReactTableInterface {
  columns: Column<any>[];
  data: Record<string, any>[];
  onSort: any;
  initialSort?: SortingRule<Record<string, any>>[];
  fetchData: ({
    pageSize,
    pageIndex,
  }: {
    pageSize: number;
    pageIndex: number;
  }) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  loading: boolean;
  pageCount: number;
  pageSize: number;
  setStartEndDate?: (range: DateRangeInterface) => void;
  rightContent?: JSX.Element;
  controlledHiddenColumns: string[];
  setControlledHiddenColumns?: Dispatch<SetStateAction<string[]>>;
  applyFilter: () => void;
  cancelFilter?: () => void;
  clearFilter: () => void;
  isHasFilter?: boolean;
  isHasSearchBox?: boolean;
  searchBoxPosition?: 'left' | 'right' | 'middle';
  datePickerPosition?: 'left-end' | 'right-end';
  itemDetailRoute?: string;
  idName?: string;
  fromWhere?: string;
  onSearch?: (keyword: string) => void;
  defaultVal?: string;
  isDisableClickRow?: boolean;
  tableToolbarContainerSxProps?: SxProps;
  tableContainerSxProps?: SxProps;
  tableSearchBoxSxProps?: SxProps;
  hasSubheader?: boolean;
  isDisableClickCells?: string[];
  filterNumber?: number;
}

interface SearchBoxInterface {
  tableSearchBoxSxProps?: SxProps;
  position: 'left' | 'right' | 'middle';
  onSearch: (keyword: string) => void;
  defaultVal: string;
}

function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: FilterProps<any>) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search here...`}
    />
  );
}

export const SearchBox = ({
  tableSearchBoxSxProps,
  position,
  onSearch,
  defaultVal,
}: SearchBoxInterface) => {
  const [value, setValue] = useState(defaultVal);
  return (
    <Box>
      <FormControl
        variant='filled'
        size='small'
        sx={{
          mr: position === 'right' ? 0 : 0,
          ml: position === 'right' ? 0 : 0,
        }}
      >
        <TextField
          id='search'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search sx={{ color: '#333333' }} />
              </InputAdornment>
            ),
            style: {
              fontFamily: 'Open Sans',
            },
          }}
          size='small'
          placeholder='Search'
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) onSearch(value);
          }}
          sx={{
            '&:before, &:after': {
              display: 'none',
            },
            ...tableSearchBoxSxProps,
          }}
        />
      </FormControl>
    </Box>
  );
};

function CustomReactTable({
  columns,
  data,
  onSort,
  initialSort,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  pageSize: controlledPageSized,
  currentPage,
  setCurrentPage,
  setStartEndDate,
  rightContent,
  controlledHiddenColumns,
  // setControlledHiddenColumns,
  applyFilter,
  cancelFilter,
  clearFilter,
  isHasFilter = true,
  isHasSearchBox,
  searchBoxPosition = 'middle',
  datePickerPosition = 'left-end',
  itemDetailRoute,
  idName,
  fromWhere,
  onSearch,
  defaultVal,
  isDisableClickRow,
  tableToolbarContainerSxProps,
  tableContainerSxProps,
  tableSearchBoxSxProps,
  hasSubheader,
  isDisableClickCells,
  filterNumber,
}: CustomReactTableInterface) {
  const navigate = useNavigate();
  const highlight = useStoreHighlightTable((state) => state.highlight);
  const highlightParam = useStoreHighlightTable(
    (state) => state.highlightParam
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage,
        pageSize: controlledPageSized,
        hiddenColumns: controlledHiddenColumns,
        sortBy: initialSort ?? [],
      },
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onSort({ sortBy });
    fetchData({ pageIndex, pageSize });
    setHiddenColumns(controlledHiddenColumns);
  }, [
    onSort,
    sortBy,
    fetchData,
    pageIndex,
    pageSize,
    setHiddenColumns,
    controlledHiddenColumns,
  ]);

  const classes = useStyles();

  const isClickableCell = (cell: any) => {
    const { column } = cell;
    if (column.Header === 'Action' || column.disableClickRow) {
      return false;
    }
    return true;
  };

  const onClickData = (id: string | number, cell: any) => {
    if (!isClickableCell(cell)) return;
    if (fromWhere) {
      navigate(`/${itemDetailRoute}/${id}`, {
        state: fromWhere,
      });
    } else {
      navigate(`/${itemDetailRoute}/${id}`);
    }
  };

  const handleChange = (_: any, value: number) => {
    setCurrentPage(value - 1);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ background: 'white', ...tableContainerSxProps }}>
        {(isHasFilter === undefined || isHasFilter === true) && (
          <Dialog
            id='filter-dialog'
            onClose={handleClose}
            open={open}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                borderRadius: '16px',
                boxShadow: '0',
              },
            }}
            maxWidth='lg'
          >
            <Box
              color='white'
              bgcolor='#023047'
              px={4}
              py={2}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              fontFamily='Poppins'
              sx={{ borderRadius: '16px 16px 0 0', fontSize: 'large' }}
            >
              Filter
              <IconButton onClick={handleClose} id='close'>
                <Close sx={{ color: 'white' }} />
              </IconButton>
            </Box>
            <Box bgcolor='white' display='flex' sx={{ padding: 4 }}>
              <FilterTableForm
                headers={headerGroups}
                applyFilter={() => {
                  applyFilter();
                  handleClose();
                }}
                cancelFilter={cancelFilter}
                clearFilter={clearFilter}
                hasSubheader={hasSubheader}
              />
            </Box>
          </Dialog>
        )}

        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          spacing={1}
          sx={{ paddingBottom: '8px' }}
        >
          <Grid item>
            <Grid container direction='row' spacing={1}>
              {isHasSearchBox && searchBoxPosition === 'left' && (
                <Grid item>
                  <SearchBox
                    tableSearchBoxSxProps={tableSearchBoxSxProps}
                    position={searchBoxPosition}
                    onSearch={onSearch!}
                    defaultVal={defaultVal !== undefined ? defaultVal : ''}
                  />
                </Grid>
              )}
              <Grid item sx={{ ...tableToolbarContainerSxProps }}>
                <ShowN
                  setVal={setPageSize}
                  curVal={pageSize}
                  listVal={[5, 10, 25, 50]}
                />
              </Grid>
              {isHasSearchBox && searchBoxPosition === 'middle' && (
                <Grid item>
                  <SearchBox
                    tableSearchBoxSxProps={tableSearchBoxSxProps}
                    position={searchBoxPosition}
                    onSearch={onSearch!}
                    defaultVal={defaultVal !== undefined ? defaultVal : ''}
                  />
                </Grid>
              )}
              {setStartEndDate && datePickerPosition === 'left-end' && (
                <Grid item>
                  <CustomDateRangePicker
                    right={false}
                    display='inline'
                    startDate=''
                    endDate=''
                    setStartEndDate={setStartEndDate}
                  />
                </Grid>
              )}
              {isHasSearchBox && searchBoxPosition === 'right' && (
                <Grid item>
                  <SearchBox
                    tableSearchBoxSxProps={tableSearchBoxSxProps}
                    position={searchBoxPosition}
                    onSearch={onSearch!}
                    defaultVal={defaultVal !== undefined ? defaultVal : ''}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={1} alignItems='center'>
              {(isHasFilter === undefined || isHasFilter === true) && (
                <Grid item>
                  <Badge
                    id='filter-number'
                    badgeContent={filterNumber ?? 0}
                    color='primary'
                    sx={{ '& .MuiBadge-badge': { right: 6, top: 38 } }}
                  >
                    <Button
                      id='filter'
                      onClick={handleClickOpen}
                      variant='outlined'
                      startIcon={<TuneOutlined />}
                      sx={{
                        borderRadius: '8px',
                        height: '46px',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                      }}
                    >
                      Filter
                    </Button>
                  </Badge>
                </Grid>
              )}
              {rightContent ? <Grid item>{rightContent}</Grid> : ''}
              {setStartEndDate && datePickerPosition === 'right-end' && (
                <Grid item>
                  <CustomDateRangePicker
                    right
                    display='inline'
                    startDate=''
                    endDate=''
                    setStartEndDate={setStartEndDate}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <TableContainer style={{ backgroundColor: 'white' }}>
          <Table {...getTableProps()} className={classes.tableTable}>
            <TableHead>
              {headerGroups.map((headerGroup, iHeaderGroup) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.tableHeader}
                  key={iHeaderGroup}
                >
                  {headerGroup.headers.map((column, iHeader) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classes.tableCell}
                      key={iHeader}
                    >
                      <Box display='flex' alignItems='center'>
                        <Box
                          {...column.getHeaderProps({
                            style: {
                              // minWidth: column.minWidth,
                              // maxWidth: column.maxWidth,
                              // width: column.width,
                              fontWeight: 700,
                              fontFamily: 'Poppins',
                            },
                          })}
                        >
                          {/* @ts-ignore */}
                          {column.render('Header')}
                        </Box>
                        <Box
                          width='16px'
                          display='flex'
                          alignItems='center'
                          ml={1}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownward sx={{ fontSize: '16px' }} />
                            ) : (
                              <ArrowUpward sx={{ fontSize: '16px' }} />
                            )
                          ) : (
                            ''
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                let classNameRow = classes.tableRow;

                if (highlight !== '') {
                  if (highlightParam !== undefined) {
                    if (highlightParam?.more === true) {
                      if (
                        // @ts-ignore
                        row.original[highlightParam?.type] >=
                          highlightParam?.number &&
                        (highlightParam?.type === 'avg_score'
                          ? highlightParam?.aboveZeroCondition !== undefined
                            ? row.original[highlightParam?.aboveZeroCondition] >
                              0
                            : true
                          : true)
                      ) {
                        classNameRow = classes.tableRowSelected;
                      }
                    } else {
                      if (
                        // @ts-ignore
                        row.original[highlightParam?.type] <
                          highlightParam?.number &&
                        (highlightParam?.type === 'avg_score'
                          ? highlightParam?.aboveZeroCondition !== undefined
                            ? row.original[highlightParam?.aboveZeroCondition] >
                              0
                            : true
                          : true)
                      ) {
                        classNameRow = classes.tableRowSelected;
                      }
                    }
                  }
                }

                return (
                  <TableRow
                    {...row.getRowProps()}
                    className={classNameRow}
                    key={i}
                  >
                    {row.cells.map((cell, iCell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={classes.tableCell}
                          key={iCell}
                          sx={{
                            fontFamily: 'Poppins',
                            cursor:
                              !isDisableClickRow &&
                              !isDisableClickCells?.includes(cell.column.id)
                                ? 'pointer'
                                : 'auto',
                          }}
                          onClick={() => {
                            if (
                              !isDisableClickRow &&
                              !isDisableClickCells?.includes(cell.column.id)
                            ) {
                              onClickData(
                                data[Number(row.id)][idName || 'id'],
                                cell
                              );
                            }
                          }}
                        >
                          {/* @ts-ignore */}
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>
                {loading && (
                  <TableCell colSpan={10000} className={classes.tableCell}>
                    Loading...
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>

          {!loading && data.length === 0 && <NoResultsFound copy={true} />}

          <Box display='flex' justifyContent='flex-end' my={2}>
            <Pagination
              count={pageOptions.length}
              page={currentPage + 1}
              variant='outlined'
              shape='rounded'
              showLastButton
              showFirstButton
              onChange={handleChange}
            />
          </Box>
        </TableContainer>
      </Box>
    </>
  );
}

export default CustomReactTable;
